import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {AsideMenuMain} from './AsideMenuMain'
import {DrawerComponent, ToggleComponent} from '../../../assets/ts/components'
import { AsideMenuItem } from './AsideMenuItem'
import { useIntl } from 'react-intl'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const {pathname} = useLocation();
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      className='hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1'
    >
      <div
        className='menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500'
        id='#kt_aside_menu'
        data-kt-menu='true'
      >
        <AsideMenuItem
          to='/dashboard'
          title="Home"
          icon='/media/ATR/icons/Icon-feather-home.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        />
        {/* <AsideMenuItem
          to='/alerts'
          title="Alerts"
          icon='/media/ATR/icons/alerts.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        /> */}
        {/* <AsideMenuItem
          to='/Downloads'
          title="Downloads"
          icon='/media/ATR/icons/Icon payment-invoice-sign-alt-o.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        /> */}
        <AsideMenuItem
          to='/orders/report'
          title="Report"
          icon='/media/ATR/icons/Icon payment-invoice-sign-alt-o.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        />
        <AsideMenuItem
          to='/resources'
          title={<span style={{ whiteSpace: 'nowrap' }}>Resources <><br></br></>Board / Effective Dates</span>}
          icon='media/ATR/icons/information-button-svgrepo-com.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        />
        {/* <AsideMenuItem
          to='/orders/report'
          title="Settings"
          icon='/media/ATR/icons/Icon feather-settings.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        /> */}
        {/* <AsideMenuItem
          to='/orders/'
          title="New Order"
          icon='/media/ATR/icons/Icon ionic-ios-add-circle-outline.svg'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className=''
        /> */}
      </div>
    </div>
  )
}

export {AsideMenu}
